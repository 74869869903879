import React, { FC } from 'react';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import heroArrow from '../../images/icons/arrowRight.svg';
import './feature-buttons.css';

interface ArrowAnchorButtonProps {
  anchor: string;
  linkText: string;
}

const ArrowAnchorButton: FC<ArrowAnchorButtonProps> = (props) => {
  return (
    <AnchorLink to={props.anchor} className="a-anchor-link-btn">
      <span className="a-anchor-link-text">{props.linkText}</span>
      <div className="a-anchor">
        <img src={heroArrow}></img>
      </div>
    </AnchorLink>
  );
};

export default ArrowAnchorButton;
