import React, { FC } from 'react';
import Img from 'gatsby-image';
import './industry-grid.css';
import { Route } from '../../models/site-links';
import { gaButtonEvent } from '../../services/visitor-info';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

interface IndustryTileProp {
  iconImage: any;
  link: Route;
  industryTitle: string;
  industryDescription: string;
}

const IndustryTile: FC<IndustryTileProp> = (props) => {
  return (
    <a
      href={props.link}
      onClick={() => gaButtonEvent(props.industryTitle, `IndustryGrid${props.industryTitle}`)}
      className="industry-tile-container bg-blue-darkest text-white border border-grey-lightest"
    >
      <div className="industry-icon-container">
        <div className="industry-icon">
          <Img fluid={props.iconImage} />
        </div>
      </div>
      <div className="industry-title text-2xl">{props.industryTitle}</div>
    </a>
  );
};

export default IndustryTile;
