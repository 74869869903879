import React, { FC } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import IndustryTile from './industry-tile';
import { Route } from '../../models/site-links';
import './industry-grid.css';

const IndustryGrid = () => {
  const data = useStaticQuery(graphql`
    query {
      avis: file(relativePath: { eq: "logos/home/avis-logo2.png" }) {
        childImageSharp {
          fixed(width: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      entertainment: file(relativePath: { eq: "icons/entertainment.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      automotive: file(relativePath: { eq: "icons/car_rental.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      banking: file(relativePath: { eq: "icons/banking.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      vape: file(relativePath: { eq: "icons/retail_vape.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      dispensaries: file(relativePath: { eq: "icons/dispensaries.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      telecom: file(relativePath: { eq: "icons/telecom.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      antifraud: file(relativePath: { eq: "icons/anti_fraud.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      retailliquor: file(relativePath: { eq: "icons/liquor_store.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      finance: file(relativePath: { eq: "icons/banking.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      fakeIDdetection: file(relativePath: { eq: "icons/fakeID.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      identification: file(relativePath: { eq: "icons/identification.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      property: file(relativePath: { eq: "icons/property_management.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <div className="industry-grid-section">
      <h2 className="text-2xl md:text-3xl font-section-headers grid-tagline">
        Learn how <span className="font-heading">Patronscan</span> is used in your industry
      </h2>
      <div className="industry-grid-container">
        <div className="industry-row">
          <IndustryTile
            iconImage={data.entertainment.childImageSharp.fluid}
            link={Route.IdScannerBars}
            industryTitle="Bars &amp; Nightclubs "
            industryDescription="Minimize Fraud, protect profits and enhance your client experience."
          ></IndustryTile>
          <IndustryTile
            iconImage={data.vape.childImageSharp.fluid}
            link={Route.IndustryVape}
            industryTitle="Vape Shops"
            industryDescription="Minimize Fraud, protect profits and enhance your client experience."
          ></IndustryTile>
          <IndustryTile
            iconImage={data.dispensaries.childImageSharp.fluid}
            link={Route.IndustryCannabis}
            industryTitle="Dispensaries"
            industryDescription="Minimize Fraud, protect profits and enhance your client experience."
          ></IndustryTile>
          <IndustryTile
            iconImage={data.telecom.childImageSharp.fluid}
            link={Route.IndustryTelecom}
            industryTitle="Telecom"
            industryDescription="Minimize Fraud, protect profits and enhance your client experience."
          ></IndustryTile>
          <IndustryTile
            iconImage={data.fakeIDdetection.childImageSharp.fluid}
            link={Route.FakeIdScanner}
            industryTitle="Fake ID Detection"
            industryDescription="Minimize Fraud, protect profits and enhance your client experience."
          ></IndustryTile>
        </div>
        <div className="industry-row">
          <IndustryTile
            iconImage={data.automotive.childImageSharp.fluid}
            link={Route.IndustryAutomotive}
            industryTitle="Automotive"
            industryDescription="Minimize Fraud, protect profits and enhance your client experience."
          ></IndustryTile>
          <IndustryTile
            iconImage={data.property.childImageSharp.fluid}
            link={Route.IndustryProperty}
            industryTitle="Property Management"
            industryDescription="Minimize Fraud, protect profits and enhance your client experience."
          ></IndustryTile>
          <IndustryTile
            iconImage={data.retailliquor.childImageSharp.fluid}
            link={Route.IndustryRetail}
            industryTitle="Retail"
            industryDescription="Minimize Fraud, protect profits and enhance your client experience."
          ></IndustryTile>
          <IndustryTile
            iconImage={data.finance.childImageSharp.fluid}
            link={Route.IndustryAntiFraud}
            industryTitle="Financial Services"
            industryDescription="Minimize Fraud, protect profits and enhance your client experience."
          ></IndustryTile>
          <IndustryTile
            iconImage={data.antifraud.childImageSharp.fluid}
            link={Route.IndustryAntiFraud}
            industryTitle="Fraud Prevention"
            industryDescription="Minimize Fraud, protect profits and enhance your client experience."
          ></IndustryTile>
        </div>
      </div>
    </div>
  );
};
export default IndustryGrid;
