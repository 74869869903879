import React from 'react';
import Hero from '../components/sections/hero';
import LeadCapture from '../components/sections/lead-capture';
import IndustryGrid from '../components/sections/industry-grid';
import { useStaticQuery, graphql } from 'gatsby';
import ConnectWithUs from '../components/sections/connect-with-us';
import Feature from '../components/sections/industry/feature-block';
import SEO from '../components/seo';
import { Route } from '../models/site-links';
import { getDemoLink } from '../services/demo-selector';
import './vaccine-passport.css';

const FakeIdScanner = () => {
  const data = useStaticQuery(graphql`
    query {
      mainHero: file(relativePath: { eq: "hero-images/main-hero.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      secure: file(relativePath: { eq: "icons/secure.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      vaccineStatus: file(relativePath: { eq: "vaccine-passport/confirmed.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      scanningID: file(relativePath: { eq: "vaccine-passport/id-scan.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      vaccineAlerts: file(relativePath: { eq: "vaccine-passport/Vaccine-Passport.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      healthQR: file(relativePath: { eq: "vaccine-passport/SMART-health-card.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      results: file(relativePath: { eq: "vaccine-passport/vaccine-pass-results.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      privacyVPass: file(relativePath: { eq: "vaccine-passport/privacy-VPass.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <div>
      <SEO
        title="Vaccine Passport ID Scanners | Patronscan"
        description="Scan IDs, catch fakes and check for proofs of vaccinations in seconds. Download our app today. 24/7 Support."
      />
      <Hero
        heroImage={data.mainHero.childImageSharp.fluid}
        showVideo={true}
        heading="Vaccine Checks Simplified"
        videoLink={'https://player.vimeo.com/video/669968158?autoplay=0&loop=0&autopause=0&muted=1'}
        subHeading="Verify age, IDs and proof of vaccines 
        with one intuitive app"
        linkText="Learn More"
        anchor="/vaccine-passport/#vac-first-feature"
        buttonId="VaccineHero:LearnMore"
      />
      <a className="anchor" id="vac-first-feature" />
      <Feature
        image={data.results.childImageSharp.fluid}
        heading="Speed and Accuracy"
        headingThin="in entrance operations"
        subHeading="See results in seconds and receive alerts for suspicious activity."
        description="Accurately verify vaccine passports and IDs in one tap to keep queues moving and patrons happy."
        linkText="Get Pricing"
        link={Route.GetPricing}
        buttonId="VaccineFeature:SpeedAndAccuracy"
      />
      <Feature
        rightAlign
        image={data.scanningID.childImageSharp.fluid}
        heading="North America's"
        headingThin="largest ID scanning network"
        subHeading="Spot trouble and catch fake IDs leveraging Patronscan's expansive network"
        description="Check IDs against the shared flagged list for known risks to public safety and disturbances."
        linkText="Book Demo"
        link={getDemoLink()}
        buttonId="VaccineFeature:LargestScanningNetwork"
      />
      <Feature
        image={data.healthQR.childImageSharp.fluid}
        heading="Supports Verifying"
        headingThin="SMART Health Cards"
        subHeading="Verifies the most common forms of vaccine passports"
        description="Compatible with federal, state and provincial vaccination passports using the SMART Health Card format"
        linkText="Sign Up"
        link={Route.TalkToAnExpert}
        buttonId="VaccineFeature:SMARTHealthCard"
      />
      <Feature
        rightAlign
        image={data.privacyVPass.childImageSharp.fluid}
        heading="Security and Privacy"
        headingThin="at the forefront"
        subHeading="Designed to only verify the QR code information required to prove vaccinations"
        description="Patronscan does not retain vaccination records. The scanner only validates the name, encryption, COVID-19 vaccinations and/or tests contained in the QR code."
        linkText="Get Pricing"
        link={Route.GetPricing}
        buttonId="VaccineFeature:SecurityPrivacy"
      />
      <Feature
        image={data.vaccineAlerts.childImageSharp.fluid}
        heading="Spot Counterfeits"
        headingThin="and catch fake IDs"
        subHeading="Trusted verification technology to spot fabricated documents and IDs"
        description="Use Patronscan’s leading algorithms to detect fake documents, fake vaccine passports and spot public safety threats"
        linkText="Learn More"
        link={Route.TalkToAnExpert}
        buttonId="VaccineFeature:SpotCounterfeits"
      />
      <div className="form-space" />
      <div className="vaccine-form">
        <LeadCapture
          sourcePage="Vaccine Passport Page"
          detailText="Get in touch to download our app today for iOS or Android"
          buttonText="Submit"
          commentPrompt="Comments"
        />
      </div>
      <div className="vaccine-industry-grid">
        <IndustryGrid />
      </div>
      <div className="vaccine-cwu">
        <ConnectWithUs
          buttonId="VaccineConnect"
          promptText="Connect with us to learn more about how Patronscan can help you."
        />
      </div>
    </div>
  );
};

export default FakeIdScanner;
