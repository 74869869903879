import React, { FC, ReactElement } from 'react';
import BackgroundImage from 'gatsby-background-image';
import Img from 'gatsby-image';
import Button from '../../components/buttons/button';
import Buttons from '../../components/buttons/feature-buttons';
import { Route } from '../../models/site-links';
import AnchorLinkButton from '../buttons/anchor-link-button';
import ArrowAnchorButton from '../buttons/arrow-anchor-button';

import './hero-banner.css';
import './hero.css';

interface HeroProps {
  heroImage: any;
  buttonId: string;
  darkBackground?: boolean;
  banner?: ReactElement;
  heading?: string;
  subHeading?: string;
  headingText?: string;
  showVideo?: boolean;
  videoLink?: string;
  featureImage?: any;
  link?: Route;
  linkText?: string;
  secondLink?: string;
  secondLinkText?: string;
  subText?: string;
  anchorLink?: string;
  anchor?: string;
  small?: boolean;
  landing?: boolean;
}

const Hero: FC<HeroProps> = (props) => {
  let heroMedia;

  if (props.showVideo) {
    heroMedia = (
      <iframe
        className="hero2-video"
        height="500px"
        src={props.videoLink}
        frameBorder="0"
        allow="autoplay; fullscreen"
        allowFullScreen
      ></iframe>
    );
  } else if (props.small) {
    heroMedia = <Img className="hero2-small-feature" fluid={props.featureImage} />;
  } else {
    heroMedia = <div className="hero2-feature-image" />;
  }

  return (
    <BackgroundImage className="w-full" fluid={props.heroImage}>
      <section id="header" className="pt-24 w-full">
        {props.banner}
        <div className="hero2-container">
          {props.small && <div className="hero2-container-sm"></div>}
          <div className="hero2-textblock">
            <h1
              className={`${
                props.darkBackground ? 'text-white' : 'text-black'
              } font-heading mb-4 font-bold leading-none hero2-tagline`}
            >
              {props.heading}
            </h1>
            <h4
              className={`${props.darkBackground ? 'text-white' : 'text-black'} hero2-sub-heading`}
            >
              {props.subHeading}
            </h4>
            <p
              className={`${
                props.darkBackground ? 'text-white' : 'text-black'
              } text-xl font-hairline max-w-2xl leading-relaxed hero2-headingText`}
            >
              {props.headingText}
            </p>
            {props.link && (
              <div className="hero2-buttons">
                <Buttons
                  buttonId={props.buttonId}
                  link={props.link}
                  linkText={props.linkText}
                  secondLink={props.secondLink}
                  secondLinkText={props.secondLinkText}
                />
              </div>
            )}
            {props.anchorLink && !props.landing && (
              <div className="hero2-buttons">
                <AnchorLinkButton heroButton link={props.anchorLink} linkText={props.linkText} />
              </div>
            )}
            {props.anchor && !props.landing && (
              <div className="a-anchr-butn-cont">
                <ArrowAnchorButton anchor={props.anchor} linkText={props.linkText} />
              </div>
            )}
            {props.landing && (
              <>
                <div>
                  <Button
                    buttonId={props.buttonId}
                    className="w-full"
                    anchor={props.anchor}
                    landing
                    text={props.linkText}
                  />
                </div>
                <div>
                  <p className="mt-3 font-bold text-white">
                    Sign up today and get{' '}
                    <span className="text-blue-hero">$1,000 off an ID scanner</span> on a 2 year
                    term
                  </p>
                </div>
              </>
            )}
          </div>
          {heroMedia}
        </div>
      </section>
    </BackgroundImage>
  );
};

export default Hero;
